import React from 'react'
import {Box,Typography} from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function Order() {
  return (
    <Box sx={{backgroundColor:"#CCE6F2",height:"100vh",alignItems:"center",display:"flex",justifyContent:"center"}}>
       <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <Box sx={{color:"#00A2E8"}}>
        <CheckCircleOutlineIcon sx={{fontSize:"150px"}}/>
        </Box>
            <Typography component="p" sx={{fontSize:"28px",fontWeight:700}}>Your Order Placed</Typography>
            <Typography component="p" sx={{fontSize:"24px",fontWeight:700,color:"#00A2E8"}}>Successfully</Typography>
       </Box>
    </Box>
  )
}

export default Order