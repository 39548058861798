import React from 'react'
import {Box,Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import Coffee from '../assets/Images/Coffee1.png'
import Product from '../assets/Images/Product.png'
import Select from '../Home/select/select'
import TextField from '@mui/material/TextField';
import Swiper from '../Home/Swiper/swiper'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button';
import Logo from '../assets/Images/Logo.jpeg'
import {useNavigate} from 'react-router-dom'
import Carousel from './Carousel/carousel'


const styleForBox = {
  mt:{xs:.8,md:2}
}


const TypoStyle = {

}




function Home() {
  const navigate = useNavigate()
  return (
    <Box sx={{width:{xs:"90%",md:"85%"},mx:"auto"}}>
      
      <Box component="img" src={Logo} sx={{width:{xs:"30%",md:"16%"},mt:4}}/>
    <Box >
      <Typography component="p" sx={{textAlign:"center",color:"#396C03",fontSize:{xs:"30px",md:"68px"},fontFamily:"Playfair Display",fontWeight:600,py:3}}>Paramakudi Super Shop</Typography>
      {/* <Swiper/> */}
        
          {/* <Box component="img" src={Coffee}  sx={{width:"100%",height:{xs:"8rem",sm:"12rem",lg:"20rem"}}}/> */}

          <Box sx={{py:10}}>
            <Carousel/>
            </Box>  
        
<Grid container>
        <Grid item xs={12} md={6}>
          <Box component="img" src={Product} sx={{width:"100%",mt:5,borderRadius:2}}/>
        </Grid>
        <Grid item xs={12} md={6} sx={{p:2}}>
        
       
<Box sx={{mt:{xs:1,md:9},width:{xs:"100%",md:"80%"},mx:"auto"}}>


        <Typography component="p" sx={{fontSize:"28px",fontWeight:500}}>Swiss Water Decaf</Typography>
        <Typography component="p" sx={{fontSize:{xs:"22px",md:"28px"}}}>₹14.00</Typography>
        <Typography component="p" sx={{fontSize:"18px",color:"#000000AB",mt:{xs:1,md:"1"}}}>An organic certified blend of coffees with a delicate cream and
         a floral aroma. Essence of cherry and macadamia for a rich, creamy espresso.</Typography>
         
         <Box sx={{display:"flex",alignItems:"center",mt:3}}>
         <Typography component="p"  sx={{ mr:1,fontWeight:600}}>Size</Typography>
         <Box sx={{width:"40%"}}>
          <Select/>
          </Box>
         </Box>
         
         <Box sx={{display:"flex",alignItems:"center",mt:3}}>
         <Typography component="p" sx={{fontWeight:600,mr:1}}>Quantity</Typography>
         <TextField
          id="outlined-number"
          size='small'
          type="number"
          sx={{width:"20%"}}
          InputLabelProps={{
            shrink: true,
           
          }}
        />
        </Box>
    
         {/* ----------------------Buy Now------------- */}

         <Button variant="contained" onClick={()=>navigate("/buy")} sx={{backgroundColor:"#00A2E8",borderRadius:0,py:0.8,mt:3,color:"white",width:{xs:"99%",md:"60%"},textAlign:"center",fontSize:"20px",textTransform:"capitalize"}}>Buy Now</Button>
         </Box>
         </Grid>
         </Grid>
{/* --------------------------------------------My Orders----------------------------- */}
         <Box>
         <Typography component="p" sx={{textAlign:"center",fontSize:"28px",fontFamily:"Playfair Display",fontWeight:600,py:5}}>My Orders</Typography>
         </Box>
{/* -----------------------------------------------My Orders Grid------------------------------------------------ */}
<Grid container sx={{mb:5}}>
        <Grid item xs={5} md={5}>
          <Box component="img" src={Product} sx={{width:{xs:"95%",md:"80%"},borderRadius:2}}/>
        </Grid>
        <Grid item xs={7} md={4} sx={{px:1}}>
        
       
<Box sx={{mt:{xs:0,md:2},width:{xs:"100%",md:"80%"},mx:"auto"}}>

        <Typography component="p" sx={{fontSize:{xs:"18px",md:"28px"}}}>Swiss Water Decaf</Typography>
        {/* <Typography component="p" sx={{fontSize:{xs:"22px",md:"28px"},mt:{xs:0.5,md:2}}}>₹14.00</Typography>
         
         <Box sx={{...styleForBox}}>
         <Typography component="p"  sx={{fontSize:"16px", mr:1,fontWeight:600}}>Size: 500g</Typography>
         </Box>
         
         <Box sx={{...styleForBox}}>
         <Typography component="p" sx={{fontSize:"16px",fontWeight:600,mr:1}}>Quantity: 1</Typography>
         </Box> */}
         <Box sx={{...styleForBox}}>
         <Typography component="p" sx={{fontSize:{xs:"14px",md:"16px"},fontWeight:600,mr:1,color:"#00A2E8"}}>Ordered on 08 May,2024</Typography>
         </Box>
         </Box>
         </Grid>
         </Grid>


    </Box>
    </Box>
  )
}

export default Home