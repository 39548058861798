import React from 'react'
import {Box,Grid} from '@mui/material'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import {useNavigate} from 'react-router-dom'
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Logo from '../assets/Images/Logo.jpeg'

function Buy() {
  const navigate = useNavigate()
  return (
    <Box sx={{width:{xs:"90%",md:"80%"},mx:"auto"}}>
             <Box component="img" src={Logo} sx={{width:{xs:"30%",md:"16%"},mt:3}}/>
        <Box>
       
        <Typography component="p" sx={{textAlign:"center",color:"#396C03",fontSize:{xs:"28px",md:"59px"},fontFamily:"Playfair Display",fontWeight:600,py:3}}>Paramakudi Super Shop</Typography>
        <Typography component="p" sx={{textAlign:"center",fontSize:{xs:"24px",md:"32px"},fontWeight:"bold",py:1}}>Checkout</Typography>
{/* -------------------------------------------------Billing Details------------------------------------------------ */}
    <Grid container>
        <Grid item xs={12} md={6}>
        <Box>
        <Typography component="p" sx={{fontSize:{xs:"24px",md:"32px"},fontWeight:"bold",py:1}}>Billing Details</Typography>
        <Box>
        <TextField
          id="outlined-password-input"
          placeholder="Name"
          type="text"
          autoComplete="current-password"
          style={{width:"90%",flexDirection:"column"}}
        />
        </Box>
        <Box sx={{mt:4}}>
        <TextField
          id="outlined-password-input"
          placeholder="Phone"
          type="text"
          autoComplete="current-password"
          style={{width:"90%",flexDirection:"column"}}
        />
        </Box>
        <Box sx={{mt:4}}>
        <TextField
          id="outlined-password-input"
          placeholder="Address"
          type="text"
          autoComplete="current-password"
          style={{width:"90%",flexDirection:"column"}}
        />
        </Box>
        <Box sx={{mt:4}}>
        <TextField
          id="outlined-password-input"
          placeholder="PIN CODE"
          type="text"
          autoComplete="current-password"
          style={{width:"90%",flexDirection:"column"}}
        />
        </Box>
        </Box>
        </Grid>
        <Grid item xs={12} md={6}>
        <Box>
        <Typography component="p" sx={{fontSize:{xs:"22px",md:"32px"},fontWeight:"bold",py:1,mt:2}}>Your order</Typography>

        <Box sx={{boxShadow: 3,borderColor:"#0000004A"}}>
            <Box sx={{width:"92%",mx:"auto",mb:5,mt:2}}>
                <Box sx={{display:"flex",justifyContent:"space-between",py:3}}>
                <Typography component="p" sx={{fontSize:{xs:"18px",md:"22px"},fontWeight:700}}>Product</Typography>
                <Typography component="p" sx={{fontSize:{xs:"18px",md:"22px"},fontWeight:700}}>Subtotal</Typography>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",borderBottom:2,mb:3,borderColor:"#0000004A"}}>
                <Typography component="p" sx={{fontSize:{xs:"16px",md:"20px"},mb:4}}>Swiss Water Decaf</Typography>
                <Typography component="p" sx={{fontSize:{xs:"16px",md:"20px"}}}>₹14.00</Typography>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                <Typography component="p" sx={{fontSize:{xs:"18px",md:"22px"},fontWeight:700}}>Subtotal</Typography>
                <Typography component="p" sx={{fontSize:{xs:"18px",md:"22px"}}}>₹14.00</Typography>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",mb:3}}>
                <Typography component="p" sx={{fontSize:{xs:"18px",md:"22px"},fontWeight:700}}>Total</Typography>
                <Typography component="p" sx={{fontSize:{xs:"18px",md:"22px"}}}>₹14.00</Typography>
                </Box>

                <Box>
                <Typography component="p" sx={{textAlign:"center",fontSize:{xs:"22px",md:"32px"},fontWeight:"bold",py:1,textTransform:"uppercase"}}>Payment Methods</Typography>
                </Box>
                <Box>
                <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"        
        defaultValue="ATM Card"        
        name="radio-buttons-group">
        <FormControlLabel value="credit" control={<Radio sx={{fontSize:"30px"}}/>} label="Credit Card"  />
        <FormControlLabel value="debit" control={<Radio />} label="Debit Card"  />
        <FormControlLabel value="cod" control={<Radio />} label="Cash On Delivery"  />
        <FormControlLabel value="upi" control={<Radio />} label="UPI"  />
      </RadioGroup>
    </FormControl>
                </Box>
            <Box sx={{textAlign:"center"}}>
            <Button variant="contained" onClick={()=>navigate("/order")} sx={{backgroundColor:"#00A2E8",borderRadius:0,py:1.5,mt:3,color:"white",width:{xs:"95%",md:"45%"},mb:3,textAlign:"center",fontSize:"14px"}}>Proceed to Payment</Button>
            </Box>


            </Box>

        </Box>


        </Box>
        </Grid>
    </Grid>

        
{/* --------------------------------------------------------Your Order---------------------------------------------------- */}
        



        </Box>




    </Box>
  )
}

export default Buy