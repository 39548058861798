import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home/home'
import Buy from './Buy/buy'
import Order from './Order/order'

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />}/>  
      <Route path="/buy" element={<Buy />}/>  
      <Route path="/order" element={<Order />}/>  
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
