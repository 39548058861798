import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import Coffee2 from '../../assets/Images/Coffee2.png'
import Coffee1 from '../../assets/Images/Coffee1.png'
import Coffee3 from '../../assets/Images/Coffee3.png'
import Box from '@mui/material/Box'

import './carousel.css';

// import required modules
import { Pagination,Autoplay } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}

        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination,Autoplay]}
        className="mySwiper"
      >
        {[0,1,2].map((item,index)=>{
            return <SwiperSlide><Box><Box component="img" src={Coffee1} sx={{maxHeight:"25rem"}}/><Box sx={{mt:1,height:"30px"}}></Box></Box></SwiperSlide>
        })}
        
      </Swiper>
    </>
  );
}