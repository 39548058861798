import React, { useState } from "react";

import Select from "react-select";



export default () => {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  const colourOptions = [
    { value: "ocean", label: "100g", color: "#00B8D9", isFixed: true },
    { value: "blue", label: "200g", color: "#0052CC", isDisabled: true },
    { value: "purple", label: "300g", color: "#5243AA" },
    { value: "red", label: "300g", color: "#FF5630", isFixed: true },
    { value: "orange", label: "400g", color: "#FF8B00" },
    { value: "yellow", label: "500g", color: "#FFC400" },
    { value: "green", label: "600g", color: "#36B37E" },
    { value: "forest", label: "700g", color: "#00875A" },
    { value: "slate", label: "800g", color: "#253858" },
    { value: "silver", label: "900g", color: "#666666" },
  ];

  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={colourOptions[0]}
        isDisabled={false}
        isLoading={false}
        isClearable={false}
        isRtl={false}
        isSearchable={false}
        name="color"
        options={colourOptions}
       
      />
    </>
  );
};
